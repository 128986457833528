import React, { useEffect } from "react";
import { Button, Paper, Stack, TextField } from "@mui/material";
import { socket } from "./socket";
import { Game } from "./types";
import { useNavigate } from "react-router-dom";

type Props = { gameId: Game["id"] | null };

export default function Nickname({ gameId }: Props) {
  const navigate = useNavigate();
  const [nickname, setNickname] = React.useState("");

  const createRoom = () => {
    socket.emit("createRoom");
  };

  const updateNickname = (gameId: Game["id"]) => {
    socket.emit("updateNickname", { roomId: gameId, data: nickname });
  };

  useEffect(() => {
    socket.on("roomCreated", (gameId: string) => {
      console.log("Game Room Created:", gameId);
      socket.emit("joinRoom", {
        roomId: gameId,
        nickname,
      });
      navigate(`/game/${gameId}`);
    });

    return () => {
      socket.off("roomCreated");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nickname]);

  return (
    <Paper sx={{ p: 5 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          gameId ? updateNickname(gameId) : createRoom();
        }}
      >
        <Stack spacing={2}>
          <TextField
            id="nickname"
            name="nickname"
            placeholder="Enter your name"
            size="small"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          <Button variant="contained" color="success" type="submit">
            {gameId ? "Join Game" : " Start a New Game"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
}
