import { Grid, Hidden, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import Word from "./Word";
import TimeLeft from "./TimeLeft";
import { useGame } from "./hooks/useGame";

export default function TopBar() {
  const { game, isCurrentPlayerActive, currentActivePlayer } = useGame();

  if (!game) return null;

  const round = game.totalRounds - game.roundsLeft + 1;

  return (
    <Paper sx={{ p: { xs: 1, md: 2 } }}>
      <Grid container sx={{ textAlign: "center", alignItems: "center" }}>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <TimeLeft />
            <Typography sx={{ fontWeight: "bold" }}>
              Round{" "}
              <Hidden mdUp>
                <br />
              </Hidden>{" "}
              {round > game.totalRounds ? game.totalRounds : round}/
              {game.totalRounds}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          {game.currentWord && (
            <Word
              word={game.currentWord}
              showComplete={
                isCurrentPlayerActive ||
                game.status === "break" ||
                game.status === "round_over"
              }
              hint={game.currentHint}
            />
          )}
          {game.status === "choosing_word" && !isCurrentPlayerActive && (
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {currentActivePlayer?.nickname} is choosing a word
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="right">Settings</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
