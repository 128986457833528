import { Box, List, ListItemButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { socket } from "./socket";
import { useNavigate } from "react-router-dom";
import { Game } from "./types";
import Nickname from "./Nickname";
import { CenterStack } from "./UIComponents";

type Props = { gameId: Game["id"] | null };

export default function JoinOrCreate({ gameId }: Props) {
  const [games, setGames] = React.useState<Game[]>([]);

  const fetchGames = async () => {
    const result = await fetch(new URL(process.env.REACT_APP_BACKEND_URL + "/list"));
    if (!result.ok) {
      throw new Error("Error fetching games");
    }
    const data = (await result.json()) as Game[];
    return data;
  };

  useEffect(() => {
    // Get request to backend to get a list of all games
    fetchGames()
      .then((data) => {
        setGames(data);
      })
      .catch((err) => {
        console.log("Error fetching games", err);
      });
  }, []);

  return (
    <CenterStack>
      <Nickname gameId={gameId} />
      <Box sx={{ mt: 6, p: 5 }}>
        <Typography textAlign="center" fontWeight="bold">
          Open Games
        </Typography>
        <List>
          {games.map((game) => (
            <ListItemButton key={game.id} component="a" href={`/game/${game.id}`}>
              {game.id.slice(0, 6)}:{" "}
              {game.players.map((player) => player.nickname).join(", ")}
            </ListItemButton>
          ))}
        </List>
      </Box>
    </CenterStack>
  );
}
