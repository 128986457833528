import React, { useEffect, useState } from "react";
import { Game, Player } from "../types";
import { GameContext } from "./useGame";
import { socket } from "../socket";
import { useSnackbar } from "notistack";

type Props = {
  children: React.ReactNode;
};

function GameContextProvider({ children }: Props) {
  const [game, setGame] = useState<Game | null>(null);
  const [playerId, setPlayerId] = useState<Player["id"] | null>(null);

  const player = game?.players.find((player) => player.id === playerId) || null;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    socket.on("updateRoom", (game: Game) => {
      console.log("Game Updated:", game);
      setGame(game);
    });
    socket.on("playerId", (playerId: Player["id"]) => {
      console.log("Player ID:", playerId);
      setPlayerId(playerId);
    });

    socket.on("startGame", (data) => {
      console.log("Game Started:", data);
    });

    socket.on("endGame", (data) => {
      console.log("Game Ended:", data);
    });

    socket.on("roomError", (errorMessage) => {
      enqueueSnackbar(errorMessage, { variant: "error" });
    });

    return () => {
      socket.off("updateRoom");
      socket.off("playerId");
      socket.off("startGame");
      socket.off("endGame");
      socket.off("roomError");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCurrentPlayerActive =
    game !== null &&
    game.currentPlayer !== undefined &&
    game.currentPlayer === player?.id;

  const currentActivePlayer =
    (game &&
      game.currentPlayer &&
      game.players?.length > 0 &&
      game.players.find((player) => player.id === game.currentPlayer)) ||
    null;

  const nextRound = () => {
    socket.emit("nextRound", { roomId: game?.id });
  };

  return (
    <GameContext.Provider
      value={{
        game,
        setGame,
        player,

        isCurrentPlayerActive,
        nextRound,
        currentActivePlayer,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}

export default React.memo(GameContextProvider);
