import React, { useEffect, useRef, useState } from "react";
import { socket } from "./socket";
import { ChatMessage, Player, Game } from "./types";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { stat } from "fs";
type Props = {
  player: Player;
  gameId: Game["id"];
  status: Game["status"];
  currentActivePlayer: Player | null;
  currentWord: string | undefined;
  players: Player[];
};

function Chat({
  gameId,
  status,
  currentWord,
  currentActivePlayer,
  players,
  player,
}: Props) {
  const playerId = player.id;
  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [textInput, setTextInput] = useState("");
  const [disabled, setDisabled] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    socket.on("message", (message: ChatMessage) => {
      if (
        message.playerId === playerId &&
        message.message === currentWord &&
        message.color === "green"
      ) {
        setDisabled(true);
      }
      setChats((prev) => [...prev, message]);
    });

    return () => {
      socket.off("message");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === "round_over") {
      setChats([]);
    }
    if (status === "guessing") {
      // Focus inputRef
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [status]);

  const guess = () => {
    if (!playerId || disabled) {
      return;
    }

    socket.emit("guess", { roomId: gameId, data: textInput });

    setTextInput("");
  };

  return (
    <div>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            guess();
          }}
        >
          {playerId !== currentActivePlayer?.id && (
            <TextField
              inputRef={inputRef}
              type="text"
              id="guess"
              size="small"
              value={textInput}
              disabled={status !== "guessing"}
              onChange={(e) => setTextInput(e.currentTarget.value.trimEnd())}
              placeholder="Guess"
              fullWidth
            />
          )}
        </form>
      </div>
      <div style={{ minHeight: 50 }}>
        {chats
          .slice()
          .reverse()
          .map((chat, i) => (
            <div style={{ color: chat.color }}>
              {players.find((p) => p.id === chat.playerId)?.nickname}: {chat.message}
            </div>
          ))}
      </div>
    </div>
  );
}

export default React.memo(Chat);
