import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Stack,
} from "@mui/material";
import React from "react";
import { socket } from "./socket";
import { Game } from "./types";

type Props = {
  game: Game;
};

export default function ChooseWord({ game }: Props) {
  const chooseWord = (wordIndex: number) => {
    socket.emit("wordChosen", { roomId: game.id, data: wordIndex });
  };
  const deleteWord = (word: string) => {
    socket.emit("deleteWord", { roomId: game.id, data: word });
  };
  return (
    <Dialog maxWidth="md" open={true}>
      <DialogTitle>Choose a word</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack direction="row" spacing={2}>
            {game.wordOptions.map((word, i) => (
              <React.Fragment>
                <Stack spacing={2}>
                  <Button
                    key={i}
                    variant="contained"
                    color="primary"
                    onClick={() => chooseWord(i)}
                  >
                    {word}
                  </Button>
                  <Button
                    key={i}
                    variant="contained"
                    color="error"
                    onClick={() => deleteWord(word)}
                  >
                    Replace
                  </Button>
                </Stack>
              </React.Fragment>
            ))}
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
