import React from "react";
import {
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Player } from "./types";

type Props = {
  players: Player[];
  currentCorrectlyGuessedPlayers: string[];
  currentActivePlayer: Player | null;
};

export default function PlayerList({
  players,
  currentCorrectlyGuessedPlayers,
  currentActivePlayer,
}: Props) {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography>Players:</Typography>
      <Box sx={{ bgcolor: "background.paper" }}>
        <List>
          {players.map((player, key) => {
            const didPlayerGuessCorrect = currentCorrectlyGuessedPlayers.includes(
              player.id
            );
            return (
              <ListItem
                key={key}
                style={{
                  background: didPlayerGuessCorrect ? "green" : "none",
                  color: didPlayerGuessCorrect ? "white" : "inherit",
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontWeight:
                        currentActivePlayer?.id === player.id ? "bold" : "normal",
                    },
                  }}
                >
                  {player.nickname} ({player.score})
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}
