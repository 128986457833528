import { Stack, styled } from "@mui/material";

export const CenterStack = styled(Stack)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100dvh",
  justifyContent: "center",
  alignItems: "center",
}));
