import { Box } from "@mui/material";
import React from "react";

interface LetterProps {
  value: string;
}

const Letter: React.FC<LetterProps> = ({ value }) => (
  <span style={{ marginRight: "5px", fontSize: "20px", fontWeight: "bold" }}>
    {value}
  </span>
);

interface WordDisplayProps {
  word: string;
  hint?: string[];
  showComplete: boolean;
}

export default function Word({ word, hint, showComplete }: WordDisplayProps) {
  const displayWord = showComplete ? Array.from(word) : hint || [];

  return (
    <Box>
      {displayWord.map((letter, index) => (
        <Letter key={index} value={letter} />
      ))}
    </Box>
  );
}
