import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import JoinOrCreate from "./JoinOrCreate";
import Game from "./Game";
import Join from "./Join";
import "./index.css";
import theme from "./theme";
import PasswordAuthenticator from "./PasswordAuthenticator";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <JoinOrCreate gameId={null} />,
      },
      {
        path: "/game/:gameId",
        element: <Game />,
      },
      {
        path: "/join/:gameId",
        element: <Join />,
      },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <PasswordAuthenticator>
          <RouterProvider router={router} />
        </PasswordAuthenticator>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
