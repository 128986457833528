import React from "react";
import { Backdrop, Box, Button, Paper, Typography } from "@mui/material";
import { Player } from "./types";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { socket } from "./socket";
import ReplayIcon from "@mui/icons-material/Replay";

type Props = { players: Player[]; gameId: string };

function GameDone({ players, gameId }: Props) {
  const rankedPlayers = players.sort((a, b) => b.score - a.score);
  const winner = rankedPlayers[0];
  const replay = () => {
    socket.emit("replay", { roomId: gameId });
  };
  return (
    <Backdrop
      open
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box>
        <Box sx={{ textAlign: "center", color: "gold" }}>
          <EmojiEventsIcon sx={{ fontSize: 100 }} />
        </Box>
        <Typography variant="h5" sx={{ mb: 4, textAlign: "center", color: "gold" }}>
          <b>{winner.nickname}</b>
          <br />
          has won
        </Typography>
        <Paper sx={{ padding: 5, mb: 6 }}>
          <table className="score-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Player</th>
                <th style={{ width: 80, textAlign: "center" }}>Score</th>
              </tr>
            </thead>
            <tbody>
              {rankedPlayers.map((player, key) => (
                <tr key={key}>
                  <td># {key + 1}</td>
                  <td>{player.nickname}</td>
                  <td style={{ textAlign: "center" }}>{player.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={replay}
            startIcon={<ReplayIcon />}
          >
            Replay
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
}

export default React.memo(GameDone);
