import { Box, ButtonBase } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
  value: string;
  onChange?: (value: string) => void;
};

export default function ColorPicker({ onChange, value }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };
  return (
    <Box sx={{ width: 200 }}>
      <ColorControl value="red" currentValue={value} onChange={handleChange} />
      <ColorControl value="green" currentValue={value} onChange={handleChange} />
      <ColorControl value="yellow" currentValue={value} onChange={handleChange} />
      <ColorControl value="orange" currentValue={value} onChange={handleChange} />
      <ColorControl value="blue" currentValue={value} onChange={handleChange} />
      <ColorControl value="lightblue" currentValue={value} onChange={handleChange} />
      <ColorControl value="violet" currentValue={value} onChange={handleChange} />
      <ColorControl value="black" currentValue={value} onChange={handleChange} />
      <ColorControl value="grey" currentValue={value} onChange={handleChange} />
    </Box>
  );
}

function ColorControl(props: {
  currentValue?: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { currentValue, value, onChange } = props;
  const checked = currentValue === value;
  return (
    <ButtonBase
      sx={{ width: 30, height: 30, background: value, color: "white" }}
      disableRipple
    >
      <input
        type="radio"
        value={value}
        checked={checked}
        style={{
          background: props.value,
          cursor: "inherit",
          position: "absolute",
          opacity: 0,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          margin: 0,
          padding: 0,
          zIndex: 1,
        }}
        onChange={(e) => {
          onChange && onChange(e);
        }}
      />
      {checked && <CheckIcon />}
    </ButtonBase>
  );
}
