import React, { useState } from "react";
import { Button, Paper, Stack, TextField } from "@mui/material";
import { CenterStack } from "./UIComponents";

interface Props {
  children: React.ReactNode;
}

const PasswordAuthenticator: React.FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");

  const authenticate: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const response = await fetch(
      new URL(process.env.REACT_APP_BACKEND_URL + "/verify"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }
    );
    const parsedResponse = (await response.json()) as { result: boolean };
    setIsAuthenticated(parsedResponse.result);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  if (!isAuthenticated) {
    return (
      <CenterStack>
        <Paper sx={{ p: 5 }}>
          <form onSubmit={authenticate}>
            <Stack spacing={2}>
              <TextField
                type="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button variant="contained" type="submit">
                Authenticate
              </Button>
            </Stack>
          </form>
        </Paper>
      </CenterStack>
    );
  }

  return <>{children}</>;
};

export default PasswordAuthenticator;
