import React from "react";
import { useEffect } from "react";
import { useGame } from "./hooks/useGame";
import { socket } from "./socket";
import Chat from "./Chat";
import Canvas from "./Canvas";
import { Box, Grid, Hidden, Paper } from "@mui/material";
import ChooseWord from "./ChooseWord";
import { useLocation } from "react-router-dom";
import PlayerRank from "./PlayerRank";
import PlayerList from "./PlayerList";
import GameDone from "./GameDone";
import TopBar from "./TopBar";
import NotStarted from "./NotStarted";
import JoinOrCreate from "./JoinOrCreate";
import Nickname from "./Nickname";
import { CenterStack } from "./UIComponents";

function Game() {
  const { game, player, isCurrentPlayerActive, currentActivePlayer } = useGame();

  const location = useLocation();
  const gameId = location.pathname.split("/")[2];

  useEffect(() => {
    if (gameId && !game) {
      socket.emit("joinRoom", {
        roomId: gameId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  useEffect(() => {
    if (game) {
      window.onbeforeunload = function () {
        return "Are you sure you want to reload! All progress will be lost";
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [game]);

  if (!game) {
    return <div>Loading...</div>;
  } else if (!player) {
    return <div>Player not found</div>;
  } else if (!player.nickname) {
    return (
      <CenterStack>
        <Nickname gameId={game.id} />
      </CenterStack>
    );
  } else {
    return (
      <Box sx={{ mt: 2, mx: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopBar />
          </Grid>
          <Hidden lgDown>
            <Grid item lg={2}>
              <PlayerList
                players={game.players}
                currentCorrectlyGuessedPlayers={game.currentCorrectlyGuessedPlayers}
                currentActivePlayer={currentActivePlayer}
              />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={isCurrentPlayerActive ? 12 : 8}
            md={isCurrentPlayerActive ? 12 : 9}
            lg={7}
          >
            <Paper sx={{ p: 2 }}>
              {game.status !== "not_started" ? (
                <Canvas
                  roomId={game.id}
                  active={isCurrentPlayerActive && game.status === "guessing"}
                  status={game.status}
                />
              ) : (
                <NotStarted game={game} player={player} />
              )}
            </Paper>
          </Grid>
          {!isCurrentPlayerActive && (
            <Grid item xs={4} md={3} lg={3}>
              <Paper sx={{ p: 2, height: "100%" }}>
                <Chat
                  player={player}
                  gameId={game.id}
                  status={game.status}
                  currentActivePlayer={currentActivePlayer}
                  currentWord={undefined}
                  players={game.players}
                />
              </Paper>
            </Grid>
          )}
          <Hidden lgUp>
            <Grid item xs={12}>
              <PlayerList
                players={game.players}
                currentCorrectlyGuessedPlayers={game.currentCorrectlyGuessedPlayers}
                currentActivePlayer={currentActivePlayer}
              />
            </Grid>
          </Hidden>
          {isCurrentPlayerActive && (
            <Grid item xs={12}>
              <Paper sx={{ p: 2, height: "100%" }}>
                <Chat
                  player={player}
                  gameId={game.id}
                  status={game.status}
                  currentActivePlayer={currentActivePlayer}
                  currentWord={undefined}
                  players={game.players}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
        {isCurrentPlayerActive && game.status === "choosing_word" && (
          <ChooseWord game={game} />
        )}
        {(game.status === "break" || game.status === "round_over") &&
          currentActivePlayer && (
            <PlayerRank
              players={game.players}
              currentWord={game.currentWord || ""}
              currentActivePlayer={currentActivePlayer}
              currentCorrectlyGuessedPlayers={game.currentCorrectlyGuessedPlayers}
            />
          )}

        {game.status === "done" && (
          <GameDone players={game.players} gameId={game.id} />
        )}
      </Box>
    );
  }
}

export default React.memo(Game);
