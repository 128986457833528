import React from "react";
import { socket } from "./socket";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {};

export default function Join({}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const gameId = location.pathname.split("/")[2];

  React.useEffect(() => {
    socket.emit("joinRoom", {
      roomId: gameId,
    });
    navigate(`/game/${gameId}`);
  }, [gameId]);

  return <div>Loading</div>;
}
