import React, { useEffect, useState } from "react";
import { Stage, Layer, Line } from "react-konva";
import { socket } from "./socket";
import ColorPicker from "./ColorPicker";
import CreateIcon from "@mui/icons-material/Create";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ClearIcon from "@mui/icons-material/Clear";
import UndoIcon from "@mui/icons-material/Undo";
import { Box, IconButton, Slider, Stack, Tooltip, Typography } from "@mui/material";
import { Game } from "./types";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { KonvaEventObject } from "konva/lib/Node";
interface LineData {
  tool: Tool;
  points: number[];
  color: string;
  strokeWidth?: number;
  fillColor?: string;
}

type Tool = "pen" | "eraser" | "fill";

interface Props {
  roomId: string;
  active: boolean;
  status: Game["status"];
}

const sceneWidth = 300;
const sceneHeight = 200;

function Canvas({ roomId, active, status }: Props) {
  const [tool, setTool] = useState<Tool>("pen");
  const [color, setColor] = useState<string>("black");
  const [strokeWidth, setStrokeWidth] = useState<number>(1);
  const [lines, setLines] = useState<LineData[]>([]);
  const [size, setSize] = useState({ width: 500, height: 400 });
  const isDrawing = React.useRef(false);

  useEffect(() => {
    socket.on("draw", (lines: LineData[]) => {
      console.log(lines);
      setLines(lines);
    });

    window.addEventListener("resize", checkSize);
    checkSize();

    return () => {
      socket.off("draw");
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  useEffect(() => {
    checkSize();
    if (status === "new_round") {
      deleteAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const undo = () => {
    if (lines.length > 0) {
      const newLines = [...lines];
      newLines.pop();
      socket.emit("draw", {
        roomId,
        data: newLines,
      });
      setLines(newLines);
    }
  };

  const checkSize = () => {
    var container = document.querySelector("#stage-parent") as HTMLElement;
    // now we need to fit stage into parent container
    var containerWidth = container.offsetWidth;
    var containerHeight = container.offsetHeight;

    // but we also make the full scene visible
    // so we need to scale all objects on canvas
    setSize({ width: containerWidth, height: containerHeight });
  };

  const scale = size.width / sceneWidth;

  const handleMouseDown = (e: KonvaEventObject<TouchEvent | MouseEvent>) => {
    if (!active || (tool !== "pen" && tool !== "eraser")) return;

    isDrawing.current = true;
    const pos = e.target.getStage()!.getPointerPosition()!;
    setLines([
      ...lines,
      {
        tool,
        points: [pos.x / scale, pos.y / scale],
        color,
        strokeWidth: strokeWidth,
      },
    ]);
    return false;
  };

  const handleMouseMove = (e: KonvaEventObject<TouchEvent | MouseEvent>) => {
    if (!active || (tool !== "pen" && tool !== "eraser")) return;

    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage!.getPointerPosition()!;
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x / scale, point.y / scale]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    const newLines = lines.concat();

    socket.emit("draw", {
      roomId,
      data: newLines,
    });
    setLines(newLines);
    return false;
  };

  const handleMouseUp = (e: KonvaEventObject<TouchEvent | MouseEvent>) => {
    if (!active || (tool !== "pen" && tool !== "eraser")) return;
    isDrawing.current = false;
    return false;
  };

  const handleFill = (index: number) => {
    if (!active || tool !== "fill") return;
    const newLines = [...lines];
    newLines[index].fillColor = color;
    setLines(newLines);
  };

  const deleteAll = () => {
    socket.emit("draw", {
      roomId,
      data: [],
    });
    setLines([]);
  };

  return (
    <Box
      id="stage-parent"
      sx={{
        width: "100%",
        userSelect: "none",
        touchAction: "none",
        "& > *": { userSelect: "none" },
      }}
    >
      <Stage
        width={sceneWidth * scale}
        height={sceneHeight * scale}
        scale={{ x: scale, y: scale }}
        onMouseDown={active ? handleMouseDown : undefined}
        onMousemove={active ? handleMouseMove : undefined}
        onTouchMove={active ? handleMouseMove : undefined}
        onTouchStart={active ? handleMouseDown : undefined}
        onTouchEnd={active ? handleMouseUp : undefined}
        onMouseup={active ? handleMouseUp : undefined}
        style={{ border: "1px solid grey", userSelect: "none" }}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke={line.color}
              strokeWidth={line.strokeWidth}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              onClick={(e) => {
                handleFill(i);
                e.cancelBubble = true;
              }}
              // hitFunc={(context, shape) => {
              //   context.beginPath();
              //   context.moveTo(line.points[0], line.points[1]);
              //   line.points.forEach((point) => {
              //     context.lineTo(point, line.points[3]);
              //   });
              //   context.closePath();
              //   context.fillStrokeShape(shape);
              // }}
              hitStrokeWidth={10}
              closed={line.fillColor !== undefined}
              fill={line.fillColor}
              globalCompositeOperation={
                line.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          ))}
        </Layer>
      </Stage>

      {active && (
        <Stack direction="row" flexWrap="wrap" spacing={2}>
          <Box sx={{ flexGrow: 1 }}>
            <Tooltip title="Draw">
              <IconButton
                onClick={() => setTool("pen")}
                color={tool === "pen" ? "primary" : undefined}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            {/* <IconButton
              onClick={() => setTool("fill")}
              color={tool === "fill" ? "primary" : undefined}
            >
              <FormatColorFillIcon />
            </IconButton> */}
            <Tooltip title="Erase">
              <IconButton
                onClick={() => setTool("eraser")}
                color={tool === "eraser" ? "primary" : undefined}
              >
                <AutoFixHighIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Undo">
              <IconButton onClick={undo}>
                <UndoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear All">
              <IconButton onClick={deleteAll}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <ColorPicker value={color} onChange={(value) => setColor(value)} />
          <div>
            <Typography gutterBottom sx={{ cursor: "default" }}>
              Stroke Size:
            </Typography>
            <Slider
              defaultValue={2}
              min={1}
              max={5}
              step={0.2}
              sx={{ width: 200 }}
              onChange={(e, value) => setStrokeWidth(value as number)}
              valueLabelDisplay="auto"
            />
          </div>
        </Stack>
      )}
    </Box>
  );
}

export default React.memo(Canvas);
