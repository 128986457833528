import React, { useEffect, useState } from "react";
import { socket } from "./socket";
import { Box, Typography } from "@mui/material";

export default function TimeLeft() {
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    socket.on("timeLeft", (timeLeft: number) => {
      setTimeLeft(timeLeft - 1);
    });
    return () => {
      socket.off("timeLeft");
    };
  }, []);
  return (
    <React.Fragment>
      <Box
        sx={{
          borderRadius: 900,
          border: "1px solid black",
          width: { xs: 40, md: 50 },
          height: { xs: 40, md: 50 },
          overflow: "clip",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          fontWeight="bold"
          sx={{ fontSize: { xs: "1.1rem", md: "1.3rem" } }}
        >
          {timeLeft}
        </Typography>
      </Box>
    </React.Fragment>
  );
}
