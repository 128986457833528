// React Context for storing  game

import { createContext, useContext } from "react";
import { Player, Game } from "../types";

export const GameContext = createContext<{
  game: Game | null;
  setGame: React.Dispatch<React.SetStateAction<Game | null>>;
  player: Player | null;
  /** Is the player the current active player in the game  */
  isCurrentPlayerActive: boolean;
  /** Advance to the next Round */
  nextRound: () => void;
  /** Current Active player in the game */
  currentActivePlayer: Player | null;
}>(null!);

export function useGame() {
  return useContext(GameContext);
}
