import React from "react";
import { Backdrop, Box, Typography } from "@mui/material";
import { Player } from "./types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  players: Player[];
  currentWord: string;
  currentActivePlayer: Player;
  currentCorrectlyGuessedPlayers: string[];
};

export default function PlayerRank({
  players,
  currentWord,
  currentActivePlayer,
  currentCorrectlyGuessedPlayers,
}: Props) {
  return (
    <Backdrop
      open
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box>
        <Typography variant="h5" textAlign="center" sx={{ mb: 4 }}>
          The word was <b>{currentWord}</b>
        </Typography>
        <table className="score-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Player</th>
              <th style={{ width: 80, textAlign: "center" }}>Score</th>
              <th>Guessed correctly</th>
            </tr>
          </thead>
          <tbody>
            {players
              .sort((a, b) => b.score - a.score)
              .map((player, key) => (
                <tr key={player.id}>
                  <td># {key + 1}</td>
                  <td>{player.nickname}</td>
                  <td style={{ textAlign: "center" }}>{player.score}</td>
                  <td>
                    {player.id === currentActivePlayer.id ? (
                      <b>-</b>
                    ) : currentCorrectlyGuessedPlayers?.includes(player.id) ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Box>
    </Backdrop>
  );
}
