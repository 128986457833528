import React from "react";
import { AppBar, Link, Toolbar, Typography } from "@mui/material";
import GameContextProvider from "./hooks/GameContextProvider";
import { Outlet } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};
function App({ children }: Props = {}) {
  return (
    <GameContextProvider>
      <AppBar position="absolute" sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
        <Toolbar>
          <Typography
            variant="h6"
            component="a"
            sx={{ textDecoration: "none", color: "white !important", mr: 3 }}
            href="/"
          >
            Montagsmaler
          </Typography>
          <Link sx={{ textDecoration: "none", color: "white !important" }} href="/">
            New Game
          </Link>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Outlet />
    </GameContextProvider>
  );
}

export default App;
