import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useEffect, useState } from "react";
import { socket } from "./socket";
import { Player, Game } from "./types";

import _ from "lodash";

type Props = {
  game: Game;
  player: Player;
};

export default function NotStarted({ game, player }: Props) {
  const [rounds, setRounds] = useState(game.totalRounds.toString());
  const [drawingTime, setDrawingTime] = useState(game.drawingTime.toString());

  useEffect(() => {
    setRounds(game.totalRounds.toString());
  }, [game.totalRounds]);

  useEffect(() => {
    setDrawingTime(game.drawingTime.toString());
  }, [game.drawingTime]);

  const isAdmin = game.players[0].id === player.id;

  const startGame = () => {
    socket.emit("startGame", { roomId: game.id, data: { rounds } });
  };
  const copyInviteLink = () => {
    navigator.clipboard.writeText(window.location.origin + "/game/" + game.id);
  };

  const handleSetRounds = (event: SelectChangeEvent) => {
    const newRounds = event.target.value;
    setRounds(newRounds);
    socket.emit("setConfig", {
      roomId: game.id,
      data: { totalRounds: parseInt(newRounds) as number },
    });
  };

  const handleDrawingTime = (event: SelectChangeEvent) => {
    const newDrawingTime = event.target.value;
    setDrawingTime(newDrawingTime);
    socket.emit("setConfig", {
      roomId: game.id,
      data: { drawingTime: parseInt(newDrawingTime) },
    });
  };

  return (
    <Box>
      <h1>Configure Game</h1>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <ChangeCircleIcon />
          <Typography component="span" sx={{ ml: 2 }}>
            Rounds:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isAdmin ? (
            <Select value={rounds} onChange={handleSetRounds} fullWidth>
              {_.range(1, 10, 1).map((amount) => (
                <MenuItem value={amount}>{amount}</MenuItem>
              ))}
            </Select>
          ) : (
            <Typography fontWeight="bold">{rounds}</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <AccessAlarmIcon />
          <Typography component="span" sx={{ ml: 2 }}>
            Drawing Time:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isAdmin ? (
            <Select value={drawingTime} onChange={handleDrawingTime} fullWidth>
              {_.range(20, 240, 10).map((time) => (
                <MenuItem value={time}>{time}</MenuItem>
              ))}
            </Select>
          ) : (
            <Typography fontWeight="bold">{drawingTime}</Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" onClick={copyInviteLink} fullWidth>
            Copy Invite Link for your Friends
          </Button>
        </Grid>
        {isAdmin && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="success"
              onClick={startGame}
              fullWidth
            >
              Start Game
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
